import { type Reactive } from 'vue';

import useVuelidate from '@vuelidate/core';

export const useFormModal = (rules: any, form: Ref | Reactive<any>, onSubmit?: () => Promise<void>, beforeShow?: (args?: any) => Promise<void>, successPayload?: () => Promise<void>, closeModalAfterSuccess?: true) => {
    const { emit } = getCurrentInstance()!;
    const v$ = useVuelidate(rules, form, { $stopPropagation: true });
    const showModal = ref(false);
    const errors = ref<string[]>([]);
    const loading = ref(false);

    const formValue = unref(form);
    const defaultValues = { ...formValue };

    function resetData() {
        Object.assign(formValue, defaultValues);
        if (v$) v$.value.$reset();
    }

    async function show(args?: any) {
        resetData();

        if (beforeShow)
            await beforeShow(args);

        showModal.value = true;
    }

    function close() {
        showModal.value = false;
    }

    async function submit() {
        if (v$ && v$.value.$invalid) {
            v$.value.$touch();
            return;
        }

        errors.value = [];
        loading.value = true;

        try {
            if (onSubmit)
                await onSubmit();

            if (closeModalAfterSuccess)
                showModal.value = false;

            if (successPayload)
                emit('success', successPayload);
        }
        catch (error) {
            errors.value = handleApiBadRequestError(error);
        }
        finally {
            loading.value = false;
        }
    }

    return { v$, showModal, errors, loading, show, close, submit, resetData }
}